my-app {
    padding: 0;
}

.xb-loader-container {
    position: absolute;
    /* top: 40%;
    left: 30%; */
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    /* Only for graceful degradation in IE9, cannot be transitioned */
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.darkHover:hover {
    background-color: #f3f2f1;
}

.lighterSeparator::after {
    background-color: rgba(210, 222, 255, 0.685) !important;
}

.xb-show-more-action {
    font-size: 12px;
    color: #0078d7;
    cursor: pointer;
    margin-left: 10px;
    display: flex;
    align-items: center;
}

.xb-show-more-action span {
    margin-left: 5px;
}

.mollie-card-component__label {
    font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    font-size: 14px;
    font-weight: 600;
    color: rgb(50, 49, 48);
    box-sizing: border-box;
    box-shadow: none;
    margin: 0px;
    display: block;
    padding: 5px 0px;
    overflow-wrap: break-word;
  }

  
.mollie-component {
    box-shadow: none;
    margin: 0px;
    padding: 5px;
    box-sizing: border-box;
    border: 1px solid rgb(96, 94, 92);
    border-radius: 2px;
    background: rgb(255, 255, 255);
    cursor: text;
    height: 32px;
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    margin-bottom: 20px;
}

.mollie-component.has-focus {
    border-width: 2px;
    border-color: rgb(0, 90, 180);
    transition: 0.3s border-color cubic-bezier(0.4, 0, 0.2, 1);
}

.mollie-component.is-invalid {
    border-color: rgb(164, 38, 44);
    transition: 0.3s border-color cubic-bezier(0.4, 0, 0.2, 1);
}

.mollie-card-component__error {
    font-size: 12px;
    margin-top: 3px;
    color: rgb(164, 38, 44);
    font-weight: 400;
}

.customChoiceGroup .ms-ChoiceField-wrapper {
    align-items: center; 
    display: flex;
  }
  
  .customChoiceGroup .ms-ChoiceField-field {
    margin: 0; 
    height: auto; 
  }

  .tenantItem {
    padding: 10px
  }

  .tenantItem:hover {
    cursor: pointer;
    background-color: #f3f2f1;
  }

  .hidden {
    display: none;
  }